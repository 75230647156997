<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-row>
                    <v-col cols="auto" align-self="center">
                        <v-btn icon color="primary" to="/verein/mitarbeiter" exact>
                            <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
                        </v-btn>
                    </v-col>
                    <v-col align-self="center">
                        <h1 class="primary--text"><span class="font-weight-light">Vereinsmitarbeiter | </span>Bearbeiten</h1>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field rounded filled v-model="trainer.vorname" label="Vorname"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field rounded filled v-model="trainer.nachname" label="Nachname"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field rounded filled v-model="trainer.funktion" label="Funktion"></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
                <v-btn outlined rounded large @click="fileupload = true">
                    <font-awesome-icon :icon="trainer.img ? 'fa-regular fa-check' : 'fa-regular fa-image-polaroid'" size="lg" class="mr-2" />
                    {{ trainer.img ? 'Bild ändern' : 'Bild hochladen' }}
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-divider class="my-4"></v-divider>
                <h2 class="text-center">Rolle auswählen</h2>
            </v-col>
            <v-col cols="6" md="4" class="h-100" align-self="center">
                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.admin ? 'success' : '#74709540'" @click="trainer.rechte = rechte[0]">
                    <h3 class="text-center pa-2">Vereins-Administrator</h3>
                    <v-divider></v-divider>
                    <v-card-text class="font-weight-bold">
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Mitarbeiter verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Vereinsinfo bearbeiten</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="4" class="h-100" align-self="center">
                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.abteilungsleiter ? 'success' : '#74709540'" @click="trainer.rechte = rechte[1]">
                    <h3 class="text-center pa-2">Abteilungsleiter</h3>
                    <v-divider></v-divider>
                    <v-card-text class="font-weight-bold">
                        <span>Zugriff auf zugewiesene Sportarten:</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Trainer verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" md="4" class="h-100" align-self="center">
                <v-card class="rounded-xl pa-3 h-100 text-center" :color="trainer.rechte.trainer ? 'success' : '#74709540'" @click="trainer.rechte = rechte[2]">
                    <h3 class="text-center pa-2">Trainer/Übungsleiter</h3>
                    <v-divider></v-divider>
                    <v-card-text class="font-weight-bold">
                        <span>Zugriff auf zugewiesene Sportangebote:</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Sportangebote verwalten</span><br>
                        <span><font-awesome-icon icon="fa-solid fa-check" class="mr-2" />Teilnehmer verwalten</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" class="text-center" v-if="trainer.rechte.abteilungsleiter">
                <v-select multiple rounded filled label="Sportarten" :items="sportarten.all" item-text="name" item-value="name" v-model="trainer.rechte.sportarten"></v-select>
            </v-col>
            <v-col cols="12">
                <v-divider class="my-4"></v-divider>
                <h2 class="text-center">Kontaktdaten</h2>
                <v-row justify="center" class="pt-4">
                    <v-col cols="12" md="6">
                        <v-text-field rounded filled v-model="trainer.email" label="E-Mail"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field rounded filled v-model="trainer.telefon" label="Telefon"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-divider class="my-4"></v-divider>
                <h2 class="text-center">Datenschutz</h2>
                <v-row justify="center">
                    <v-col cols="12" md="4">
                        <v-checkbox color="primary" label="E-Mail darf veröffentlicht werden" v-model="trainer.public_data.email"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox color="primary" label="Telefonnummer darf veröffentlicht werden" v-model="trainer.public_data.telefon"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox color="primary" label="Telefonnummer kann auf WhatsApp kontaktiert werden" v-model="trainer.public_data.whatsapp"></v-checkbox>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="text-center">
                <v-divider class="my-4"></v-divider>
                <v-btn rounded :loading="creating" large color="primary" @click="update()">
                    Speichern
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            v-model="fileupload"
            :max-width="$vuetify.breakpoint.mdAndUp ? '1200' : 'none'"
            :overlay-opacity="
            $vuetify.breakpoint.mdAndUp ? 0.85 : undefined
            "
            :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
            :transition="
            $vuetify.breakpoint.mdAndUp
                ? 'dialog-transition'
                : 'dialog-bottom-transition'
            "
            :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
            :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
            content-class="rounded-xl"
        >
            <v-card class="rounded-xl">
                <v-toolbar color="primary" dark>
                    <v-card-title>
                        Bild hochladen
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row justify="center" class="my-4">
                    <v-col :cols="imageData ? 12 : 10">
                        <v-image-input
                        v-model="imageData"
                        :image-quality="0.5"
                        clearable
                        accept="image/*"
                        :hideActions="imageData ? false : true"
                        :imageWidth="500"
                        :imageMinScaling="'cover'"
                        :imageHeight="500"
                        :full-width="true"
                        image-format="jpg"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-center"
                        v-if="!imageData"
                    >
                        Für eine gute Darstellung wähle bitte ein Bild mit
                        mindestens 600 x 800 Pixeln.
                    </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions right>
                    <v-btn
                        color="primary"
                        right
                        rounded
                        :loading="uploading"
                        @click="upload()"
                    >
                        Speichern
                    </v-btn>
                    <v-btn
                        color="secondary"
                        right
                        rounded
                        @click="closeDialog()"
                    >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, updateDoc, where } from '@firebase/firestore'
import { mapGetters } from 'vuex'
import router from '../../../router'
import { getDownloadURL, getStorage, ref, uploadString } from '@firebase/storage'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

export default {
    name: 'Mitarbeiter',
    data(){
        return {
            fileupload: false,
            uploading: false,
            imageData: '',
            creating: false,
            create_mitarbeiter: false,
            rechte: [
                {
                    admin: true,
                },
                {
                    admin: false,
                    sportarten: [],
                    abteilungsleiter: true,
                    trainer: false,
                },
                {
                    admin: false,
                    abteilungsleiter: false,
                    trainer: true,
                }
            ],
            trainer: {
                vorname: '',
                nachname: '',
                email: '',
                emailconfirm: '',
                rechte: '',
                img: '',
                imgRef: '',
                telefon: '',
                public_data: {telefon: '', email: '', whatsapp: ''}
            },
            mitarbeiter: [],
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    sortable: true,
                },
                {
                    text: 'E-Mail',
                    value: 'email',
                    sortable: true,
                },
                {
                    text: 'Rechte',
                    value: 'rechte',
                }
            ],
            search: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            sportarten: 'sportarten'
        }),
        valid(){
            if(this.trainer.vorname && this.trainer.nachname && this.trainer.email && this.trainer.email == this.trainer.emailconfirm && this.trainer.rechte){
                return true
            }
            return false
        }
    },
    mounted(){
        if(!this.user.data?.rechte?.admin){
            router.push('/')
        }
        else {
            this.load_user()
        }
    },
    methods: {
        async load_user(){
            onSnapshot(
                doc(getFirestore(), 'User/'+this.$route.params.uid),
                mitarbeiter => {
                    this.creating = false
                    this.create_mitarbeiter = false
                    this.trainer = Object.assign({id: mitarbeiter.id, funktion: '', telefon: '', public_data: {telefon: false, email: false, whatsapp: false, sportarten: []}}, mitarbeiter.data())
                    this.fileupload = false
                    this.imageData = ''
                    this.uploading = false
                    this.mitarbeiter = []
                }
            )
        },
        closeDialog() {
            this.fileupload = false
            this.uploading = false
            this.imageData = ''
        },
        async upload() {
            const datum = new Date();
            const storage = getStorage();
            this.uploading = true
            if (this.imageData) {
                const image = this.imageData.replace('data:image/png;base64,', '')
                const storageRef = ref(storage, '/user/'+datum.getTime()+'.jpg');
                uploadString(storageRef, image, 'base64').then(snapshot => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    this.trainer.img = downloadURL
                    this.trainer.imgRef = '/user/'+datum.getTime()+'.jpg'
                    this.uploading = false
                    this.fileupload = false
                });
                })
            }
        },
        async update(){
            this.creating = true
            try {
                await updateDoc(doc(getFirestore(), 'User/'+this.trainer.id), {
                    vorname: this.trainer.vorname || '',
                    nachname: this.trainer.nachname || '',
                    rechte: this.trainer.rechte,
                    funktion: this.trainer.funktion || '',
                    img: this.trainer.img || 'https://finde-deinen-sport.app/img/user_icon.png',
                    imgRef: this.trainer.imgRef || '',
                    email: this.trainer.email || '',
                    telefon: this.trainer.telefon || '',
                    public_data: this.trainer.public_data,
                })
                await setDoc(doc(getFirestore(), 'Vereine/'+this.user.data.verein.id+'/Mitarbeiter/'+this.trainer.id), {
                    vorname: this.trainer.vorname || '',
                    nachname: this.trainer.nachname || '',
                    funktion: this.trainer.funktion || '',
                    img: this.trainer.img || 'https://finde-deinen-sport.app/img/user_icon.png',
                    imgRef: this.trainer.imgRef || '',
                    email: this.trainer.email || '',
                    telefon: this.trainer.telefon || '',
                    public_data: this.trainer.public_data,
                })
                this.creating = false
                Toast.fire({
                    icon: 'success',
                    title: 'Erfolgreich gespeichert!'
                })
            }
            catch(error){
                this.creating = false
                Toast.fire({
                    icon: 'error',
                    title: 'Es ist ein Fehler aufgetreten',
                    text: error
                })
            }
        }
    } 
}
</script>